import { UserPermissionsEnum } from 'api';

export const currentUserHasPermission = (permissions: UserPermissionsEnum[], permission: UserPermissionsEnum) =>
  !!permissions.find((perm) => perm === permission);

export const currentUserHasCreateIssuerPermission = (permissions: UserPermissionsEnum[]) =>
  currentUserHasPermission(permissions, UserPermissionsEnum.CREATE_ISSUER);

export const currentUserHasValidateCryptoSecuritiesPermission = (permissions: UserPermissionsEnum[]) =>
  currentUserHasPermission(permissions, UserPermissionsEnum.CAN_VALIDATE_CRYPTO_SECURITIES);
