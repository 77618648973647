import { AdminIssuerDetail } from 'api';
import { camelCase } from 'change-case';
import { compact } from 'lodash';
import { hideCentralCharacters } from 'ui/helper/hideCharacters';
import React from 'react';
import Translate from 'ui/atoms/translate';
import DocumentModal from 'src/libraries/document-modal';

interface IssuerDataProps {
  issuer: AdminIssuerDetail;
  canEdit: boolean;
  showConfigData: boolean;
  setEditCompanyDataModal: (val: boolean) => void;
  setEditCeoModal: (val: boolean) => void;
  setEditBankAccountModal: (val: boolean) => void;
  setEditConfigurationModal: (val: boolean) => void;
}

const useIssuerData = (props: IssuerDataProps) => {
  const {
    issuer: { naturalPerson, legalPerson, bankAccount, canCreateCryptoSecurities, autoValidation },
    canEdit,
    showConfigData,
    setEditCompanyDataModal,
    setEditCeoModal,
    setEditBankAccountModal,
    setEditConfigurationModal,
  } = props;

  const issuerData = {
    companyData: {
      key: 'companyData',
      fields: {
        companyName: legalPerson?.companyName,
        street: legalPerson?.street,
        city: compact([legalPerson?.zip, legalPerson?.city]).join(' '),
        country: <Translate name={`countries.${legalPerson?.country}`} />,
        commercialRegister: legalPerson?.commercialRegister,
        commercialRegisterNumber: legalPerson?.commercialRegisterNumber,
        commercialRegisterStatement: legalPerson?.commercialRegisterStatement && (
          <Translate
            name="dashboardInvestorSettings.companyData.viewDocument"
            args={[
              (text: string, key) => {
                if (!legalPerson?.commercialRegisterStatement) return;
                return (
                  <DocumentModal key={key} {...legalPerson.commercialRegisterStatement}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        ),
        listOfShareholders: legalPerson?.listOfShareholders && (
          <Translate
            name="dashboardInvestorSettings.companyData.viewDocument"
            args={[
              (text: string, key) => {
                if (!legalPerson?.listOfShareholders) return;
                return (
                  <DocumentModal key={key} {...legalPerson?.listOfShareholders}>
                    {text}
                  </DocumentModal>
                );
              },
            ]}
          />
        ),
      },
      editButtons: [
        {
          display: canEdit,
          text: 'common.edit',
          onClick: () => setEditCompanyDataModal(true),
        },
      ],
    },
    ceo: {
      key: 'ceo',
      fields: {
        salutation: naturalPerson?.salutation && (
          <Translate name={`personalInformation.salutations.${camelCase(naturalPerson?.salutation)}`} />
        ),
        forename: naturalPerson?.forename,
        surname: naturalPerson?.surname,
        email: naturalPerson?.email,
      },
      editButtons: [
        {
          display: canEdit,
          text: 'common.edit',
          onClick: () => setEditCeoModal(true),
        },
      ],
    },
    bankData: {
      key: 'bankData',
      fields: {
        iban: bankAccount?.iban && hideCentralCharacters(bankAccount?.iban, 4, -2),
        accountHolder: bankAccount?.accountHolder,
        currency: bankAccount?.currency,
        bank: bankAccount?.bank,
        bic: bankAccount?.bic,
        country: bankAccount?.country && <Translate name={`countries.${bankAccount?.country}`} />,
      },
      editButtons: [
        {
          display: canEdit,
          text: 'common.edit',
          onClick: () => setEditBankAccountModal(true),
        },
      ],
    },
    configuration: {
      key: 'configuration',
      fields: {
        canCreateCryptoSecurities: <Translate name={`commonOptions.${canCreateCryptoSecurities}`} />,
        automaticValidationEnabled: <Translate name={`commonOptions.${autoValidation}`} />,
      },
      editButtons: [
        {
          display: canEdit,
          text: 'common.edit',
          onClick: () => setEditConfigurationModal(true),
        },
      ],
    },
  };

  const userSections = [
    ...(showConfigData ? [issuerData.configuration] : []),
    issuerData.companyData,
    issuerData.ceo,
    issuerData.bankData,
  ];

  return userSections;
};

export default useIssuerData;
