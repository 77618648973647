import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import every from 'lodash/every';
import { compact } from 'lodash';
import omit from 'lodash/omit';
import find from 'lodash/find';
import FormGroup from '../molecules/form-group';
import InputAtom, { InputProps } from '../atoms/input';
import GroupToggleAtom from '../atoms/group-toggle';
import CheckboxAtom, { CheckboxProps } from '../atoms/checkbox';
import RadioAtom from '../atoms/radio';
import SelectAtom, { SelectProps } from '../atoms/select';
import FileUploadAtom from 'ui/atoms/file-upload/single-file-upload';
import MultipleFilesUploadAtom from 'src/ui/atoms/file-upload/multiple-file-upload';
import { FormState, Inputs, StateErrors, StateValues, useFormState } from 'react-use-form-state';
import ServerError from '../types/server-error';
import { handleError } from '../helper/error-handling';
import useTranslate from '../hooks/use-translate';
import Button from '../atoms/button';
import ActionButtonsAtom, { ActionButtonsProps } from 'ui/molecules/action-buttons';
import Translate from '../atoms/translate';
import {
  validateBic,
  validateEmailAddress,
  validateEthAddress,
  validateStellarAddress,
  validatePastDate,
  validateTaxId,
  validateEthChecksum,
  validatePhoneNumber,
} from '../helper/validators';
import StrengthIndicator, { PasswordStrength } from '../atoms/strength-indicator';
import IBAN from 'iban';
import Hint from '../atoms/hint';
import { ButtonProps } from 'ui/atoms/button';
import { countDecimals, toNumber } from '../helper/money';
import NumberAtom from '../atoms/number';
import BigNumber from 'bignumber.js';
import DatePickerAtom, { DatePickerProps } from 'ui/atoms/date-picker';
import DateInputAtom, { DateInputProps } from 'ui/atoms/date-input';
import * as Styled from './styled';
import Spacing from '../types/spacing';
import InputIcon from '../molecules/input-icon';
import { camelCaseToSnake } from '../helper/case-transforms';

const makeForm = <Fields,>({ modal = false }: { modal?: boolean } = { modal: false }) => {
  type FormErrorsState = StateErrors<Fields>;
  type FormFieldValidity<Fields> = {
    [F in keyof Fields]?: boolean;
  };
  type FormHookState = [FormState<Fields, FormErrorsState>, Inputs<Fields>];
  type FormValidationCallback = (
    value: string | File | Date,
    values: StateValues<Fields>,
    numberOfInputs: number,
  ) => boolean;

  interface FormProps {
    initial?: Partial<Fields>;
    onSubmit?: (values: StateValues<Fields>) => void;
    onChange?: (values: StateValues<Fields>) => void;
    error?: ServerError | null;

    /**
     * i18nKey can be used to auto grab translations for labels and requirement errors
     *
     * - labels: {118nKey}.fields.{fieldName}.label
     * - label with pronoun: {i18nKey}.fields.{fieldName}.labelWithPronoun
     * - group info: {i18nKey}.fields.{fieldName}.info
     * - field should not be empty error: {i18nKey}.fields.{fieldName}.notEmptyError
     * - field errors (override from server): {i18nKey}.fields.{fieldName}.fieldError
     *
     * @default "form"
     */
    i18nKey?: string;
    className?: string;
  }

  interface FormGroupProps {
    name: keyof Fields;
    label?: ReactNode;
    occupyHiddenLabelSpace?: boolean;
    info?: ReactNode;
    required?: boolean;
    requiredError?: ReactNode;
    fieldError?: ReactNode;
    spacing?: Spacing;
    forceLabelOnNewLine?: boolean;
    className?: string;

    /**
     * Indicates only validation errors, hides positive validation
     */
    simple?: boolean;
  }

  interface FormValidationProps {
    children?: ReactNode;
    validate: FormValidationCallback;
    error?: ReactNode;

    /**
     * a blocker validation prevents other validators to validate
     * before resolving the requirement issues of a blocker validatiion
     */
    blocker?: boolean;
  }

  interface FormPasswordGroupProps {
    passwordField: {
      name: keyof Fields;
      label?: ReactNode;
    };
    passwordRepeatField: {
      name: keyof Fields;
      label?: ReactNode;
    };
    passwordStrength?: PasswordStrength;
  }

  type FormInputProps = Omit<InputProps, 'required'>;
  type FormSelectProps = Omit<SelectProps, 'required'>;
  interface MultipleUploadProps {
    documentTypes: Array<any>;
  }

  interface FormCheckboxProps extends Omit<CheckboxProps, 'required'> {
    children: ReactNode;
    value?: string;
  }

  interface FormRadioProps extends Omit<CheckboxProps, 'required'> {
    children: ReactNode;
    value: string;
    spacing?: Spacing;
  }

  interface FormValueProviderProps {
    children: (values: StateValues<Fields>) => ReactNode;
  }

  interface FormSubComponents {
    Group: typeof Group;
    Validation: typeof Validation;
    Input: typeof Input;
    Select: typeof Select;
    Checkbox: typeof Checkbox;
    Radio: typeof Radio;
    GroupToggle: typeof GroupToggle;
    Submit: typeof Submit;
    ActionButtons: typeof ActionButtons;
    Validators: typeof Validators;
    PasswordGroup: typeof PasswordGroup;
    PasswordInput: typeof PasswordInput;
    ValueProvider: typeof ValueProvider;
    GenericErrorMessages: typeof GenericErrorMessages;
    I18nKey: typeof I18nKey;
    FileUpload: typeof FileUpload;
    MultipleFilesUpload: typeof MultipleFilesUpload;
    DatePicker: typeof DatePicker;
    DateInput: typeof DateInput;
  }

  type FormGroupTypes = 'input' | 'select' | 'radio' | 'checkbox' | 'raw' | 'file';

  type GroupContextValues = FormGroupProps & {
    inputs: Inputs<Fields>;
    valid?: boolean;
    required?: boolean;

    setType: Dispatch<SetStateAction<FormGroupTypes | undefined>>;
    setNumberOfInputs: Dispatch<SetStateAction<number>>;
  };

  type FormContextValues = {
    state: FormHookState;
    getErrorForField: (field: string) => ReactNode | undefined;

    clearedFieldErrors: Partial<Fields>;
    setClearedFieldErrors: Dispatch<SetStateAction<Partial<Fields>>>;

    validity: FormFieldValidity<Fields>;
    setValidity: Dispatch<SetStateAction<FormFieldValidity<Fields>>>;

    i18nKey?: string;

    getRemainingError: () => ReactNode | undefined;

    showValidatedForm?: boolean;
  };

  const GroupContext = createContext<GroupContextValues | undefined>(undefined);
  const FormContext = createContext<FormContextValues | undefined>(undefined);
  const I18nKeyContext = createContext<string | undefined>(undefined);

  const useGroupContext = () => {
    const groupContext = useContext(GroupContext);

    if (!groupContext) throw new Error(`Control elements needs to be placed inside a 'Form.Group' component`);

    return groupContext;
  };

  const useGroupType = (type?: FormGroupTypes) => {
    const groupContext = useGroupContext();

    useEffect(() => {
      if (type) {
        groupContext.setType(type);
        groupContext.setNumberOfInputs((numberOfInputs) => numberOfInputs + 1);
        return () => {
          groupContext.setType(undefined);
          groupContext.setNumberOfInputs((numberOfInputs) => numberOfInputs - 1);
        };
      }
    }, [type]);
  };

  const useFormContext = () => {
    const formContext = useContext(FormContext);

    if (!formContext) throw new Error(`Control elements needs to be placed inside a 'Form' component`);
    return formContext;
  };

  const useI18nKey = () => {
    const { i18nKey: keyFromFormContext } = useFormContext();
    const keyFromContext = useContext(I18nKeyContext);

    return keyFromContext || keyFromFormContext;
  };

  const Validation: FunctionComponent<FormValidationProps> = () => null;

  const mapInputTypeToFormStateInputType = (type?: string) => {
    switch (type) {
      case 'password':
        return 'password';
      case 'text':
        return 'text';
      case 'date':
        return 'date';
      case 'number':
        return 'number';
    }
    return 'text';
  };

  const Input: FunctionComponent<FormInputProps> = (props) => {
    const [hasFocus, setHasFocus] = useState(false);

    const { name, inputs, valid, required, label } = useGroupContext();
    const { disabled, placeholder, type, isCurrency, isPercentage, autoFocus, maxLength } = props;

    useGroupType('input');

    const inputFunction = inputs[mapInputTypeToFormStateInputType(type)];

    return (
      <InputAtom
        {...inputFunction({
          name,
          onBlur: () => setHasFocus(false),
        })}
        autoFocus={autoFocus}
        disabled={disabled}
        isCurrency={isCurrency}
        isPercentage={isPercentage}
        onFocus={() => setHasFocus(true)}
        required={required}
        // validate only when there is no focus (Form guidelines: Inline error messages)
        valid={hasFocus ? undefined : valid}
        label={label}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    );
  };

  const PasswordInput: FunctionComponent<FormInputProps> = (props) => {
    const [hasFocus, setHasFocus] = useState(false);
    const { name, inputs, valid, required, label } = useGroupContext();
    const { placeholder, type, isCurrency } = props;
    const [showPassword, setShowPassword] = useState(false);

    useGroupType('input');

    const inputFunction = inputs[mapInputTypeToFormStateInputType(type)];

    const onClickIcon = () => {
      setShowPassword(!showPassword);
    };

    return (
      <InputIcon
        {...inputFunction({
          name,
          onBlur: () => setHasFocus(false),
        })}
        type={showPassword ? 'text' : 'password'}
        icon={showPassword ? 'eye-open' : 'eye-closed'}
        iconActive
        iconSize="large"
        onClickIcon={onClickIcon}
        isCurrency={isCurrency}
        onFocus={() => setHasFocus(true)}
        required={required}
        // validate only when there is no focus (Form guidelines: Inline error messages)
        valid={hasFocus ? undefined : valid}
        label={label}
        placeholder={placeholder}
        {...props}
      />
    );
  };

  const FileUpload: FunctionComponent = (props) => {
    const { name, inputs, required, valid } = useGroupContext();
    const { showValidatedForm } = useFormContext();

    useGroupType('file');

    return (
      <FileUploadAtom
        {...inputs.raw({
          name,
          touchOnChange: true,
        })}
        showRequiredError={showValidatedForm && required && !valid}
        required={required}
        {...props}
      />
    );
  };

  const MultipleFilesUpload: FunctionComponent<MultipleUploadProps> = (props) => {
    const { name, inputs, required, valid } = useGroupContext();
    const { showValidatedForm } = useFormContext();

    useGroupType('file');

    return (
      <MultipleFilesUploadAtom
        {...inputs.raw({
          name,
          touchOnChange: true,
        })}
        showValidatedForm={showValidatedForm}
        showRequiredError={showValidatedForm && required && !valid}
        required={required}
        {...props}
      />
    );
  };

  const DatePicker: FunctionComponent<DatePickerProps> = (props) => {
    const { name, inputs, required, label } = useGroupContext();

    useGroupType('raw');
    const inputFunction = inputs[mapInputTypeToFormStateInputType('date')];

    return (
      <DatePickerAtom
        {...inputFunction({
          name,
        })}
        sendEventAsValue
        required={required}
        label={label}
        {...props}
      />
    );
  };

  const DateInput: FunctionComponent<Omit<DateInputProps, 'onChange'>> = (props) => {
    const { name, inputs, required, valid } = useGroupContext();

    const inputFunction = inputs[mapInputTypeToFormStateInputType('text')];

    useGroupType('raw');

    return (
      <DateInputAtom
        valid={valid}
        {...inputFunction({
          name,
        })}
        required={required}
        {...props}
      />
    );
  };

  const Select: FunctionComponent<FormSelectProps> = (props) => {
    const { name, inputs, valid, required, label } = useGroupContext();

    const formContext = useFormContext();
    const [{ values, setField }] = formContext.state;

    useGroupType('select');

    return (
      <SelectAtom
        {...inputs.raw({
          name,
        })}
        required={required}
        label={label}
        name={name.toString()}
        // validate only when there is no focus (Form guidelines: Inline error messages)
        valid={valid}
        {...props}
        value={values[name]}
        onChange={(evt) => {
          if (props.onChange) {
            props.onChange(evt);
          }
          // @ts-ignore
          setField(name, evt);
        }}
      />
    );
  };

  const GroupToggle: FunctionComponent = ({ children }) => {
    return <GroupToggleAtom>{children}</GroupToggleAtom>;
  };

  const Checkbox: FunctionComponent<FormCheckboxProps> = (props) => {
    const { value, checked, onChange } = props;
    const { name, inputs, required, valid } = useGroupContext();

    useGroupType('checkbox');

    const inputCheckboxProps = { ...inputs.checkbox(name, value) };

    if (checked !== undefined && onChange) {
      //controlled input
      inputCheckboxProps.checked = checked;
      inputCheckboxProps.onChange = onChange;
    }

    return <CheckboxAtom valid={valid} {...props} {...inputCheckboxProps} required={required} />;
  };

  const Radio: FunctionComponent<FormRadioProps> = (props) => {
    const { value } = props;
    const { name, inputs, required, valid } = useGroupContext();

    useGroupType('radio');

    return <RadioAtom valid={valid} {...props} {...inputs.radio(name, value)} required={required} />;
  };

  const Group: FunctionComponent<FormGroupProps> = (props) => {
    const {
      children,
      label,
      occupyHiddenLabelSpace,
      info,
      name,
      required,
      simple,
      requiredError,
      spacing,
      fieldError: customFieldError,
      className,
      forceLabelOnNewLine,
    } = props;

    const { state, getErrorForField, clearedFieldErrors, setValidity, showValidatedForm } = useFormContext();

    const i18nKey = useI18nKey();

    const [type, setType] = useState<FormGroupTypes>();
    const [numberOfInputs, setNumberOfInputs] = useState<number>(0);
    const translate = useTranslate();

    const [{ values, touched }, inputs] = state;

    // get the field errors (e.g. from server)
    const fieldErrorStr = getErrorForField(name as string) || getErrorForField(camelCaseToSnake(name as string));
    const fieldError = !clearedFieldErrors[name] && fieldErrorStr;

    // TODO(geforcefan): i dont like this automatic translation stuff, so much
    //  messy code is produced, talk to ux team to get rid of labels and errors with pronoun, dont want them
    const labelsAndMessages = useMemo(() => {
      const labelWithProNoun = translate(`${i18nKey}.fields.${String(name)}.labelWithPronoun`);

      return {
        label: label || translate(`${i18nKey}.fields.${String(name)}.label`),
        info: info || translate(`${i18nKey}.fields.${String(name)}.info`),
        requiredError: requiredError ||
          translate(`${i18nKey}.fields.${String(name)}.notEmptyError`) ||
          (labelWithProNoun && <Translate name={`form.errors.required.pronoun`} args={[labelWithProNoun]} />) || (
            <Translate name={`form.errors.required.${type}`} />
          ),
        fieldError:
          (fieldError && (customFieldError || translate(`${i18nKey}.fields.${String(name)}.fieldError`))) || fieldError,
      };
    }, [translate, i18nKey, name, label, info, type, requiredError, fieldError, customFieldError]);

    const validatorElements = [
      // inject a "NotEmpty" validator on required groups
      ...(required ? [Validators.NotEmpty(labelsAndMessages.requiredError)] : []),
      // ... merge all validator elements
      ...React.Children.toArray(children)
        .filter(React.isValidElement)
        .filter(({ props }: any) => typeof props.validate === 'function'),
    ];

    const validators = validatorElements.map(({ props }: any) => ({
      met: props.validate(values[name] || '', values, numberOfInputs),
      description: React.Children.count(props.children) && props.children,
      error: props.error,
      blocker: props.blocker,
    }));

    const hasValidators = !!validators.length;

    const isAllValidatorsMet = every(validators.map(({ met }) => met));

    // valid when: group required and all validators are valid
    const isRequiredValid = required && isAllValidatorsMet;

    // valid when: group is not required, empty field or all validators are valid
    const isNonRequiredValid = !required && (!values[name] || isAllValidatorsMet);

    const isValid = isRequiredValid || isNonRequiredValid;

    // report validity to form context
    useEffect(() => {
      setValidity((validity) => ({
        ...validity,
        [name]: isValid,
      }));
      return () => {
        setValidity((validity) => omit(validity, [name as string]));
      };
    }, [name, isValid]);

    // show input validation (red or green outline) on:
    // - group has at least 1 validator
    // - blurred required field
    // - blurred, optional non empty fields
    // statement can be simplified, but keep this for better understanding of the rules

    const showInputValidation =
      (hasValidators && !required && touched[name] && !!values[name]) || (required && showValidatedForm);

    // on multi requirements, show validation also when field is not empty and group has at least one 1 validator
    const showMultiRequirementValidation = hasValidators && (!!values[name] || showInputValidation);

    const showRawValidation = ['raw', 'file'].includes(type || '') && !!values[name];

    // build ui requirements
    const groupRequirements = validators
      .filter(({ description }) => description)
      .map((validator) => {
        return {
          ...validator,
          // show validation on multi requirement only when the field is not empty
          met: showMultiRequirementValidation ? validator.met : undefined,
        };
      });

    const hasMultipleValidators = groupRequirements.length > 1;

    const hasUnmetBlockerValidators = find(validators, {
      met: false,
      blocker: true,
    });

    // build error messages ui
    const validationErrorMessages = compact(
      validators
        .filter(({ met }) => met === false)
        // show error messages when input validation is also shown
        .filter(() => showInputValidation || showRawValidation)
        // omit all validator error messages which has no helper text when multiple validators exists
        .filter(({ description }) => (hasMultipleValidators ? !description : true))
        // omit all non blocker error messages, when there is any unmet blocker validation
        .filter(({ blocker }, i, messages) => (hasUnmetBlockerValidators ? blocker : true))
        .map(({ error }) => error),
    );
    // - validator errors has priority over field errors, since the form has to be valid
    //   before considering external errors
    // - show validator errors OR fields errors, never both at the same time
    const errorMessages = (validationErrorMessages.length && validationErrorMessages) || labelsAndMessages.fieldError;
    const hideRequirements = !!errorMessages && !hasMultipleValidators;

    // on basic groups, we won't show positive validation, just the negative ones
    const isInputValid = isValid === true && simple ? undefined : isValid;

    return (
      <GroupContext.Provider
        value={{
          inputs,
          // valid rule: when form is valid AND no field error is present (e.g. from the server is present)
          // we could have added the field error check to the isValid const above,
          // but we dont want to pass that to the submit validation, this place is more sufficient, since
          // this is more a ui thing
          valid: showInputValidation ? isInputValid && !fieldError : undefined,
          required,
          setType,
          setNumberOfInputs,
          label: labelsAndMessages.label,
          ...props,
        }}
      >
        <FormGroup
          requirements={!hideRequirements ? groupRequirements : []}
          occupyHiddenLabelSpace={occupyHiddenLabelSpace}
          label={['input', 'select'].includes(type || '') ? null : labelsAndMessages.label}
          optional={!required}
          error={errorMessages}
          info={!errorMessages && labelsAndMessages.info}
          spacing={spacing}
          hasLabelOnSameRow={['radio'].includes(type || '') && !forceLabelOnNewLine}
          className={className}
        >
          {children}
        </FormGroup>
      </GroupContext.Provider>
    );
  };

  const ValueProvider: FunctionComponent<FormValueProviderProps> = ({ children }) => {
    const formContext = useFormContext();
    const [{ values }] = formContext.state;

    return <>{children(values)}</>;
  };

  const Submit: FunctionComponent<Omit<ButtonProps, 'type'>> = (props) => {
    return (
      <div>
        <GenericErrorMessages />
        <ActionButtonsAtom>
          <Button {...props} type="submit" />
        </ActionButtonsAtom>
      </div>
    );
  };

  const ActionButtons: FunctionComponent<Omit<ActionButtonsProps, 'type'>> = (props) => {
    return (
      <div>
        <GenericErrorMessages />
        <ActionButtonsAtom {...props} />
      </div>
    );
  };

  const I18nKey: FunctionComponent<{ i18nKey: string }> = ({ i18nKey, children }) => {
    return <I18nKeyContext.Provider value={i18nKey}>{children}</I18nKeyContext.Provider>;
  };

  const Form: FunctionComponent<FormProps> & FormSubComponents = ({
    initial,
    children,
    onSubmit = () => {},
    onChange = () => {},
    error,
    i18nKey = 'form',
    className,
  }) => {
    const [showValidatedForm, setShowValidatedForm] = useState(false);
    const [clearedFieldErrors, setClearedFieldErrors] = useState<Partial<Fields>>({});
    const [validity, setValidity] = useState<FormFieldValidity<Fields>>({});

    // at some point we should either implement an own form state handler or update to a newer version
    const formState = useFormState<Fields>(initial, {
      onBlur(e) {
        // clear field errors of field
        setClearedFieldErrors((errors) => ({
          ...errors,
          [e.target?.name]: true,
        }));
      },
      onChange(e, stateValues, nextValues) {
        onChange(nextValues);
      },
    });

    const [{ values }] = formState;

    const isFormValid = useMemo(() => {
      return every(Object.values(validity));
    }, [validity]);

    // refactor error handler with proper field mapping
    const { getErrorForField, getRemainingError } = handleError({
      error,
      translate: useTranslate(),
    });

    // when new server errors arrives, reset all cleared errors
    useEffect(() => {
      setClearedFieldErrors({});
    }, [error]);

    // when form is valid, clear form validation error message
    useEffect(() => {
      if (isFormValid) setShowValidatedForm(false);
    }, [isFormValid]);

    const validateInputs = useCallback(() => {
      let inputs = document.getElementsByTagName('input');

      for (let input in inputs) {
        const name = inputs[input].name;
        const isValidInput = validity[name as keyof Partial<Fields>];
        if (name && isValidInput === false) {
          const inputSelector = 'input[name=' + name + ']';
          const inputToValidate = document.querySelector<HTMLInputElement>(inputSelector);
          if (!inputToValidate) return;
          inputToValidate.focus();
          inputToValidate.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          break;
        }
      }
    }, [validity]);

    return (
      <Styled.Form
        onSubmit={(e) => {
          e.preventDefault();
          if (isFormValid) {
            onSubmit(values);
          } else {
            validateInputs();
            setShowValidatedForm(true);
          }
        }}
        $modal={modal}
        className={className}
        noValidate
      >
        <FormContext.Provider
          value={{
            i18nKey,

            getErrorForField,
            getRemainingError,
            state: formState,

            clearedFieldErrors,
            setClearedFieldErrors,

            validity,
            setValidity,

            showValidatedForm,
          }}
        >
          {children}
        </FormContext.Provider>
      </Styled.Form>
    );
  };

  const PasswordGroup: FunctionComponent<FormPasswordGroupProps> = ({
    passwordField,
    passwordRepeatField,
    passwordStrength,
  }) => {
    return (
      <>
        <Group
          required={true}
          name={passwordField.name}
          label={passwordField.label || <Translate name="form.labels.password" />}
        >
          <PasswordInput />
          <StrengthIndicator passwordStrength={passwordStrength || undefined} hideWeakValidation={false} />
          <Validation
            validate={() =>
              passwordStrength
                ? passwordStrength === PasswordStrength.STRONG || passwordStrength === PasswordStrength.MEDIUM
                : false
            }
          />
        </Group>
        <Group
          required={true}
          name={passwordRepeatField.name}
          label={passwordRepeatField.label || <Translate name="form.labels.passwordRepeat" />}
        >
          <PasswordInput />
          {Validators.EqualsField(passwordField.name, <Translate name="form.errors.passwordNotEquals" />)}
        </Group>
      </>
    );
  };

  const GenericErrorMessages: FunctionComponent = () => {
    const { getRemainingError } = useFormContext();

    const genericErrorMessages = getRemainingError();

    return <>{genericErrorMessages && <Hint variant="danger">{genericErrorMessages}</Hint>}</>;
  };

  const Validators = {
    NotEmpty: (error?: ReactNode, helper?: ReactNode) => (
      <Validation
        validate={(value: any, values: any, numberOfInputs) => {
          if (Array.isArray(value) && value[0]?.file instanceof File) return true; // multiple files
          if (Array.isArray(value)) return value.length === numberOfInputs; // checkbox groups
          return !!value;
        }}
        error={error}
        blocker={true}
      >
        {helper}
      </Validation>
    ),
    Integer: (error?: ReactNode, helper?: ReactNode) => (
      <Validation
        validate={(value: string) => Number.isInteger(toNumber(value))}
        error={error || <Translate name="form.errors.integer" />}
      >
        {helper}
      </Validation>
    ),
    Range: (
      min: number | BigNumber | undefined,
      max: number | BigNumber | undefined,
      error?: ReactNode,
      helper?: ReactNode,
    ) => {
      if (min === undefined && max === undefined) return null;

      return (
        <Validation
          validate={(value: string) => {
            value = value.replace(',', '.');
            const minRequirementMet =
              min !== undefined
                ? min instanceof BigNumber
                  ? new BigNumber(value).gte(min)
                  : parseFloat(value) >= min
                : true;

            const maxRequirementMet =
              max !== undefined
                ? max instanceof BigNumber
                  ? new BigNumber(value).lte(max)
                  : parseFloat(value) <= max
                : true;

            return minRequirementMet && maxRequirementMet;
          }}
          error={
            error ||
            (min !== undefined && max !== undefined ? (
              <Translate
                name="form.errors.minMax"
                args={[<NumberAtom key={0}>{min}</NumberAtom>, <NumberAtom key={1}>{max}</NumberAtom>]}
              />
            ) : (
              (min !== undefined && (
                <Translate name="form.errors.min" args={[<NumberAtom key={0}>{min}</NumberAtom>]} />
              )) ||
              (max !== undefined && (
                <Translate name="form.errors.max" args={[<NumberAtom key={0}>{max}</NumberAtom>]} />
              ))
            ))
          }
        >
          {helper}
        </Validation>
      );
    },
    MaxDecimals: (maxDecimals: number, error?: ReactNode, helper?: ReactNode) => (
      <Validation
        validate={(value: string) => countDecimals(toNumber(value)) <= maxDecimals}
        error={
          error || <Translate name="form.errors.maxDecimals" args={[<NumberAtom key={0}>{maxDecimals}</NumberAtom>]} />
        }
      >
        {helper}
      </Validation>
    ),
    Email: () => <Validation validate={validateEmailAddress} error={<Translate name="form.errors.email" />} />,
    EqualsField: (name: keyof Fields, error?: ReactNode, helper?: ReactNode) => (
      <Validation validate={(value, values) => values[name] === value} error={error}>
        {helper}
      </Validation>
    ),
    IBAN: (error?: ReactNode, helper?: ReactNode) => (
      <Validation validate={IBAN.isValid} error={error || <Translate name="form.errors.IBAN" />}>
        {helper}
      </Validation>
    ),
    PhoneNumber: (error?: ReactNode) => (
      <Validation validate={validatePhoneNumber} error={error || <Translate name="form.errors.phoneNumber" />} />
    ),
    EthereumAddress: (error?: ReactNode, helper?: ReactNode) => (
      <Validation validate={validateEthAddress} error={error || <Translate name="form.helper.tokenAddressEthereum" />}>
        {helper || <Translate name="form.helper.tokenAddressEthereum" />}
      </Validation>
    ),
    StellarAddress: (error?: ReactNode, helper?: ReactNode) => (
      <Validation
        validate={validateStellarAddress}
        error={error || <Translate name="form.helper.tokenAddressStellar" />}
      >
        {helper || <Translate name="form.helper.tokenAddressStellar" />}
      </Validation>
    ),
    TokenAddressChecksum: (error?: ReactNode, helper?: ReactNode) => (
      <Validation validate={validateEthChecksum} error={error || <Translate name="form.errors.tokenAddressChecksum" />}>
        {helper || <Translate name="form.helper.tokenAddressChecksum" />}
      </Validation>
    ),
    TaxId: () => <Validation validate={validateTaxId} error={<Translate name="form.errors.taxId" />} />,
    Bic: () => <Validation validate={validateBic} error={<Translate name="form.errors.bic" />} />,
    FileSize: (maxSize: number) => (
      <Validation
        validate={(value: File) => (value.size ? maxSize > value.size : true)}
        error={<Translate name="form.errors.fileSize" />}
      />
    ),
    Over18: () => (
      <Validation
        validate={(value: Date) => validatePastDate(value, 18)}
        error={<Translate name="errors.investorUnderAge" />}
      />
    ),
    PastDate: (yearsInPast: number = 0) => (
      <Validation
        validate={(value: Date) => validatePastDate(value, yearsInPast)}
        error={<Translate name="errors.futureBirthDate" />}
      />
    ),
  };

  Form.ValueProvider = ValueProvider;
  Form.Group = Group;
  Form.Validation = Validation;
  Form.Input = Input;
  Form.Select = Select;
  Form.Checkbox = Checkbox;
  Form.Radio = Radio;
  Form.GroupToggle = GroupToggle;
  Form.Validators = Validators;
  Form.PasswordGroup = PasswordGroup;
  Form.PasswordInput = PasswordInput;
  Form.GenericErrorMessages = GenericErrorMessages;
  Form.Submit = Submit;
  Form.ActionButtons = ActionButtons;
  Form.I18nKey = I18nKey;
  Form.FileUpload = FileUpload;
  Form.MultipleFilesUpload = MultipleFilesUpload;
  Form.DatePicker = DatePicker;
  Form.DateInput = DateInput;

  return Form;
};

export default makeForm;
